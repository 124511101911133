
  import { Component, Vue, Watch } from "vue-property-decorator";
  import {
    readBlogs,
    readBlogPage,
    readBlogQuery,
    readBlogsTotal,
  } from "@/store/main/getters";
  import { dispatchSearchBlogs } from "@/store/main/actions";
  import { commitSetBlogQuery, commitSetBlogPage } from "@/store/main/mutations";
  import BlogCard from "@/components/BlogCard.vue";
  import { debounce } from "@/utils";

  const debouncedSearch = debounce(dispatchSearchBlogs, 300);

  @Component({
    components: {
      BlogCard,
    },
  })
  export default class BlogIndex extends Vue {
    get query() {
      return readBlogQuery(this.$store);
    }

    set query(query: string | null) {
      commitSetBlogQuery(this.$store, query);
    }

    get page() {
      return readBlogPage(this.$store);
    }

    set page(page: number) {
      commitSetBlogPage(this.$store, page);
    }

    get blogs() {
      return readBlogs(this.$store);
    }

    get pages() {
      return Math.ceil(readBlogsTotal(this.$store) / 24);
    }

    @Watch("query")
    async onQueryChanged() {
      this.page = 1;
      await this.updateBlogs();
    }

    @Watch("page")
    async onPageChanged() {
      await this.updateBlogs();
    }

    async updateBlogs() {
      await debouncedSearch(this.$store, { query: this.query, page: this.page });
    }

    public async mounted() {
      this.updateBlogs();
    }
  }
